<template>
	<div class="forgetpassword">
		<div v-if="successRegister" class="forgetpassword-container">
			<img src="@/assets/yrylogo.png" alt="" />

			<el-form
				:model="ruleForm"
				status-icon
				:rules="rules"
				ref="ruleForm"
				class="demo-ruleForm"
				style="width: 400px"
			>
				<el-form-item prop="mobile">
					<el-input
						autocomplete="off"
						prefix-icon="el-icon-mobile-phone"
						placeholder="请输入手机号"
						v-model="ruleForm.mobile"
					></el-input>
				</el-form-item>
				<el-form-item class="usercode" prop="usercode">
					<el-input
						autocomplete="off"
						prefix-icon="el-icon-message"
						placeholder="请输入验证码"
						v-model="ruleForm.SMSVerificationCode"
						onkeyup="value=value.replace(/[^\d]/g,'')"
					></el-input>
					<el-button
						style="margin-left: 10px; width: 110px"
						:disabled="!isNaN(codeText)"
						@click="getCode"
						>{{ codeText }}</el-button
					>
				</el-form-item>
				<el-form-item
					:rules="[
						{
							required: true,
							message: '请输入密码',
							trigger: 'blur',
						},
						{
							min: 5,
							max: 25,
							message: '长度在 5 到 25个字符',
						},
						{
							pattern:
								/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,20}$/,
							message: '密码必须是由4-20位字母+数字组合',
						},
					]"
					prop="password"
				>
					<el-input
						prefix-icon="el-icon-lock"
						type="password"
						v-model="ruleForm.password"
						autocomplete="off"
						placeholder="请输入密码"
					></el-input>
				</el-form-item>
				<el-form-item prop="checkPass">
					<el-input
						prefix-icon="el-icon-lock"
						type="password"
						v-model="ruleForm.checkPass"
						autocomplete="off"
						placeholder="请输入确认密码"
					></el-input>
				</el-form-item>

				<el-form-item class="forgetpassword-btn">
					<el-button
						style="width: 100%"
						type="primary"
						@click="submitForm('ruleForm')"
						:loading="loading"
						>提交</el-button
					>
					<el-button
						style="width: 100%"
						@click="$router.replace('/login')"
						>返回</el-button
					>
				</el-form-item>
			</el-form>
		</div>
		<div class="success-register" v-else>
			<el-result icon="success"> </el-result>
			<p>
				修改成功,{{ second }}秒后将自动跳转到登录页面,<span
					@click="$router.replace('/login')"
					>跳转到登录页面</span
				>
			</p>
		</div>
	</div>
</template>

<script>
import { sendSMSCode, forgetPassword } from '@/api/index.js';
export default {
	name: 'forgetpassword',
	data() {
		const checkUserName = (rule, value, callback) => {
			let reg = /^1[345789]\d{9}$/;
			if (!reg.test(value)) {
				callback(new Error('请输入正确的手机号'));
			} else {
				callback();
			}
		};

		const validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.ruleForm.password) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		const usercode = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入验证码'));
			} else {
				callback();
			}
		};
		return {
			ruleForm: {
				SMSVerificationCode: '',
				mobile: '',
				password: '',
				checkPass: '',
				systematic: 2,
			},
			codeText: '获取验证码',
			rules: {
				checkPass: [
					{
						required: true,
						validator: validatePass2,
						trigger: 'blur',
					},
				],
				mobile: [
					{
						required: true,
						validator: checkUserName,
						trigger: 'blur',
					},
				],
				usercode: [{ validator: usercode, trigger: 'blur' }],
			},
			loading: false,
			successRegister: true,
			second: 5,
			Timeout: null,
			codeText: '获取验证码',
		};
	},
	created() {},
	methods: {
		async getCode() {
			const res = await sendSMSCode({ mobile: this.ruleForm.mobile });

			if (res.returncode !== 0) {
				// this.$message({
				// 	message: res.message,
				// 	type: 'warning',
				// });
				return false;
			}
			this.$message({
				message: '验证码已发送！',
				type: 'success',
			});
			clearInterval(this.Timeout);
			this.codeText = 60;
			this.Timeout = setInterval(() => {
				if (this.codeText == 1) {
					clearInterval(this.Timeout);
					this.codeText = '获取验证码';
					return false;
				}
				this.codeText = this.codeText - 1;
			}, 1000);
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.forgetPassword();
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		async forgetPassword() {
			this.loading = true;

			const res = await forgetPassword(this.ruleForm);
			this.loading = false;

			if (res.returncode === 0) {
				this.count();

				this.successRegister = false;
				this.$message({
					message: '修改成功，请登录！',
					type: 'success',
				});
			} else {
				this.$message({
					message: res.message,
					type: 'error',
				});
			}
		},
		count(num = 3) {
			setTimeout(() => {
				this.second = num - 1;
				if (!(this.second < 1)) {
					this.count(this.second);
				} else {
					this.$router.replace('/index');
				}
			}, 1000);
		},
	},
};
</script>

<style lang="scss" scoped>
.forgetpassword {
	overflow: auto;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
	img {
		position: absolute;
		z-index: 1;
		left: 0;
		right: 0;
		margin: auto;
		top: 70px;
		height: 44px;
	}
	// > img {
	// 	position: absolute;
	// 	top: 100px;
	// }
}
/* reset element-ui css */
.forgetpassword-container {
	display: flex;
	justify-content: center;
	border-radius: 10px;
	padding: 170px 0px 20px 0px;
	position: relative;
}
.forgetpassword-text {
	position: absolute;
	z-index: 1;
	left: 60px;
	top: 140px;
	color: #000;
	font-weight: 800;
	cursor: pointer;
	font-size: 16px;
}
::v-deep .forgetpassword-btn div {
	display: flex;
}
.success-forgetpassword p {
	font-weight: 800;
	span {
		color: #5660d5;
		text-decoration: underline;
		cursor: pointer;
	}
}
::v-deep .usercode > div {
	display: flex;
}
</style>
